export default [
    //Empty-Ish Project
    {
        title: "",
        subTitle: "",
        description: '',
        //Optional (pre_image_text)
        //delete if uneeded         
        pre_image_text : "",
        image: [{
            image: "",
            caption: ""
        },
        {
            image: "",
            caption: ""
        }
    ],
        softwareUsed: <ul>
                <li>C#</li>
                <li>DIKUArcade</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended C# knowledge</li>
                <li>Software Development knowledge such as:</li>
                <ul>
                    <li>Design Patterns</li>
                    <li>Refactoring</li>
                    <li>Test-Driven Development and debugging.</li>
                    <li>Testing: Whitebox and Blackbox.</li>
                    <li>Agile Development.</li>
                    <li>Report writing and UML.</li>
                    <li>Frameworks</li>
                </ul>
        </ul>
    },
    //PROJECT: Breakout
    {
        title: "Breakout",
        subTitle: "Assignment: Recreate Breakout for the Software Development exam",
        description: 'For the Software Development exam we had to create\n' +
        ' a few projects. One of the main aspects of Software Development was learning to work with test driven\n' 
        +' development and Agile Development. Therefore we had to create projects over a series of deadlines.' ,
        image: [{
            image: "Breakout.png",
            caption: "Image of the games first level"
        },
        {
            image: "Breakout_MainMenu.png",
            caption: "Image of the main menu"
        }
    ],
        video: [{
            video: "GameplayBreakout.mp4",
            caption: "Gameplay from the finished game"
        }],
        softwareUsed: <ul>
                <li>C#</li>
                <li>DIKUArcade</li>
            </ul>,
        skillsAcquired : <ul>
            <li>Extended C# knowledge</li>
                <li>Software Development knowledge such as:</li>
                <ul>
                    <li>Design Patterns</li>
                    <li>Refactoring & Technical Debt</li>
                    <li>Test-Driven Development and debugging.</li>
                    <li>Testing: Whitebox and Blackbox.</li>
                    <li>Agile Development.</li>
                    <li>Report writing and UML.</li>
                    <li>Secure Programming</li>
                    <li>Frameworks and libraries (DIKUArcade & libraries we wrote to extend it)</li>
                </ul>
        </ul>
    },
    //PROJECT: GALAGA
    {
        title: "Galaga",
        subTitle: "Assignment: Recreate Galaga for the Software Development course",
        description: 'This project primarily focused on learning us the basics of\n'
        +'working with the DIKUArcade framework as well as\n' 
        +'agile team based development, design patterns and testing',
        image: [{
            image: "Picture2.png",
            caption: "Image of the games first level"
        },
        {
            image: "Picture1.png",
            caption: "Image of the main menu"
        }
    ],
        softwareUsed: <ul>
                <li>C#</li>
                <li>DIKUArcade</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended C# knowledge</li>
                <li>Software Development knowledge such as:</li>
                <ul>
                    <li>Design Patterns</li>
                    <li>Refactoring</li>
                    <li>Test-Driven Development and debugging.</li>
                    <li>Testing: Whitebox and Blackbox.</li>
                    <li>Agile Development.</li>
                    <li>Report writing and UML.</li>
                    <li>Frameworks</li>
                </ul>
        </ul>
    },
    // Drone Simulation
    {
        title: "Drone Simulation",
        subTitle: "Assignment: Create drones and simulate their flying.",
        description: 'This project focused on methods and using mathematics\n' 
        +'(Trigonomatry) to create your own algorithm that would check if a list of\n' 
        +'drones collided and if so update it.',
        image: [{
            image: "Picture1.JPG",
            caption: "Algorithm checking for the collision"
        },
    ],
        softwareUsed: <ul>
                <li>F#</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended F# knowledge</li>
                <li>Using mathematics to create and implement algorithms</li>
        </ul>
    },
    //Linear Algebra
    {
        title: "Linear Algebra Algorithms",
        subTitle: "Assignment: Implement linear algebra algorithms in Python or F#",
        description: 'This project required us to implement learned algorithms in\n'
        +'either Python or F# without the usage of libraries such as numpy.',
        image: [{
            image: "Picture1.JPG",
            caption: "Gramschmidt algorithms using previously created method"
        },
    ],
        softwareUsed: <ul>
                <li>Python</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended Python knowledge</li>
                <li>Learning to implement and work with mathematical algorithms</li>
        </ul>
    },
    //Secret Marshal
    {
        title: "Secret Marshal",
        subTitle: "Goal: Recreate and translate Secret Hitler to a VRChat game",
        description: <p>Secret Hitler is a secret role game where you have to figure out how is Facist and who is Liberal and stop
        Hitler from gaining power. Despite it's facist name it has nothing to do with facism and is simply a theme
        that the creators chose. The reason I changed the name is because of the rules of VRChat. My goal was to 
        recreate the game in VRChat to make it easier to play it with people in the future.
        <br></br>Some of the biggest challenges were to change the game from a board game to a video game.
        </p>,
        pre_image_text : "The game has not yet been released as I have not had the opportunity to test it with real players",
        image: [{
            image: "CardEnacted.JPG",
            caption: "Image of the board after voting"
        },
        {
            image: "VotesRevealed.JPG",
            caption: "Players podium after voting"
        },
        {
            image: "Discard Card.JPG",
            caption: "President discarding a card"
        },
        {
            image: "Discard Card chan.JPG",
            caption: "Prime Minister discarding a card"
        },
    ],
        softwareUsed: <ul>
                <li>C#</li>
                <li>Unity</li>
                <li>Udon (VRChat Framework)</li>
                <li>Udonsharp (Library that allows C# to be used instead of udon graphs)</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Learning and working with limitations and improvements from a framework</li>
                <li>Networking and syncing between players</li>
                <li>Analysing and translating gameplay from one media to another</li>
                <li>Managing and organising a schedule</li>
        </ul>
    },
    //Algorithm Visualiser
    {
        title: "Algorithm Visualiser",
        subTitle: "Goal: Apply mathematical algorithms in code and learn basic of GUI applications",
        description: <p>With most of my non game projects being console based applications I decided to create this application as my 
        second GUI based application to learn and expand my knowledge of how GUI works with programming.
        <br></br>
        Algorithm Visualiser specifically gives me the challenge of combining backend algorithms with visualisations 
        which proved to be a challenge as I had to call visualiser methods during the run of algorithms to correctly visualise
        it.
        <br></br>
        I used Windows Forms as it was the default one I found when I looked up GUI and looked in Visual Studio.
        </p>,
        image: [{
            image: "Picture1.png",
            caption: "Image of the visualiser pre running"
        },
        {
            image: "Picture2.png",
            caption: "Image of the visualiser in motion"
        }, 
        ],
    video: [{
        video: "demonstrationVideo.mp4",
        caption: <p>
            Demonstration of a some of the algorithms in use <br></br> 
                Warning: Flashing Lights
        </p>
            
    }],
        softwareUsed: <ul>
                <li>C#</li>
                <li>Windows Forms</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended C# knowledge</li>
                <li>Basic knowledge of GUI (Windows Forms)</li>
                <li>Basic knowledge of application building</li>
                <li>Extended knowledge of implementing mathematical algorithms</li>
        </ul>
    },
    //Calculator
    {
        title: "Calculator",
        subTitle: "Goal: Learn basics of GUI and how calculators work",
        description: <p>This was my first attempt of making a GUI based application. I chose a calculator as I thought it was a good
        beginner project and a good way to learn how a calculator actually works. I believe it might still have some bugs
        <br></br>
        I used Windows Forms as it was the default one I found when I looked up GUI and looked in Visual Studio.
        </p>,
        image: [{
            image: "Picture1.png",
            caption: "Image of the visualiser pre running"
        },
        {
            image: "Picture2.png",
            caption: "Image of the visualiser in motion"
        }, 
        ],
    video: [{
        video: "demonstrationVideo.mp4",
        caption: <p>
            Demonstration of a some of the algorithms in use <br></br> 
                Warning: Flashing Lights
        </p>
            
    }],
        softwareUsed: <ul>
                <li>C#</li>
                <li>Windows Forms</li>
            </ul>,
        skillsAcquired : <ul>
                <li>Extended C# knowledge</li>
                <li>Basic knowledge of GUI (Windows Forms)</li>
                <li>Basic knowledge of application building</li>
                <li>knowledge of how to implementen mathemtics to code</li>
        </ul>
    },
    //Website
    {
        title: "Website",
        subTitle: "Goal: Recreate my old HTML CSS website in React utilizing component",
        description: <p>
            The goal was to recreate my website I created in HTML and CSS. The goal was to recreate
            it using as many components as possible and data sets to simplify the process of updating 
            the website portfolio. The images shows an example of how the react components are used to make
            it more generic to change.
        </p>,
        image: [{
            image: "Picture1.png",
            caption: "Picture of the data that creates a clickable instance on the portfolio page"
        },
        {
            image: "Picture2.png",
            caption: "Picture of the clickable instance created from the data"
        },
        {
            image: "Picture3.png",
            caption: "Code that turns data into a clickable instance"
        }
    ],
        softwareUsed: <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>React</li>
        </ul>,
        skillsAcquired : <ul>
            <li>Knowledge of React</li>
            <li>Knowledge of CSS and HTML</li>
            <li>Basic knowledge of website building</li>
        </ul>
    },
]