import React from "react";
import { Link } from "react-router-dom";
import './Card.css';

export default function Card(props) {
  return (
    <div className="card"> 
        <div className="card-information">
          <h3 className="title">{props.title}</h3>
          <br />
            <Link to={`/Project/${props.title}`}>
              <img className="card-image" src={`/images/Portfolio/${props.img}`} alt={props.title} />
            </Link>
          <br />
          <figcaption className="description">{props.description}</figcaption>
        </div>
    </div>
  );
}
