import React from 'react';
import './HeroSectionPortfolio.css';
import Card from './Card';
import ProjectsData from './ProjectsData';

export default function HeroSection() {
  const schoolCardElements = ProjectsData
  .filter(data => data.type === "School")
  .map(data => (
    <Card
      title={data.title}
      description={data.description}
      img={data.image}
      id={data.id}
    />
  ));

  const personalCardElements = ProjectsData.filter(data => data.type === "Personal")
  .map((data) => (
    <Card
      title={data.title}
      description={data.description}
      img={data.image}
      id={data.id}
    />
  ));
  return (
    <div className="hero-container-Portfolio">
      <h1>Portfolio</h1>
      <h2 className='ProjectType'>
        <strong>School Projects</strong>
      </h2>
      <div className="card-section">
        <h2>
          {schoolCardElements}
        </h2>
      </div>
      <h2 className='ProjectType'>
        <strong>Personal Projects</strong>
      </h2>
      <h2>
        {personalCardElements}
      </h2>
    </div>
  );
}
