import React from 'react'
import HeroSection from './HeroSection'

export default function Home() {
  return (
    <>
      <HeroSection />
    </>
  )
}
