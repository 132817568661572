import React from 'react'
import HeroSectionContact from './HeroSectionContact'

export default function Contact() {
  return (
    <>
      <HeroSectionContact />
    </>
  )
}
