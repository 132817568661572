import React from 'react';
import { useParams } from 'react-router-dom';
import PortfolioData from './PortfolioData'; // Assuming this import is correct
import './Project.css';

export default function Project() {
  const { title } = useParams();
  const portfolioProject = PortfolioData.filter(data => data.title === title);
  if (portfolioProject.length === 0) {
    return (
      <div className="project">
        <h1>Project not written about yet</h1>
      </div>
    );
  }
  const projectImages = portfolioProject[0]?.image?.map(element => (
    <p className="projectCardImage" key={element.image}>
      <img src={`/images/PortfolioBlogs/${portfolioProject[0].title}/${element.image}`} alt={element.image} />
      <figcaption className = "ImagesCaption">{element.caption}</figcaption>
    </p>
  ));
  const projectVideo = portfolioProject[0]?.video?.map(element => (
    <div className="projectCardVideo" key={element.video}>
      <video className="card-video-controls" controls width="250">
      <source className="projectCardVideo" src={`/images/PortfolioBlogs/${portfolioProject[0].title}/${element.video}`} type="video/mp4" />
      </video>      
      <figcaption>{element.caption}</figcaption>
    </div>
  ));

  return (
    <div className="project">
      <div className="ProjectDescription">
        <h1>{portfolioProject[0].title}</h1>
        <h2>{portfolioProject[0].subTitle}</h2>
        <p>{portfolioProject[0].description}</p>
        <p>The Software Used:</p>
          {portfolioProject[0].softwareUsed}
        <p>Skills Acquired:</p>
          {portfolioProject[0].skillsAcquired}
        <div className="preImageDescription">
          {portfolioProject[0].pre_image_text}
          </div>
        <div className="ProjectImages">
          {projectImages}
        </div>
        <div className="ProjectVideo">
          {projectVideo}
        </div>
      </div>
    </div>
  );
}
