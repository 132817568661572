// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Pages/HomePage/Home';
import Portfolios from './components/Pages/PortfoliosPage/Portfolios';
import Project from './components/Pages/PortfoliosPage/Project';
import './App.css'
import Contact from './components/Pages/ContactsPage/Contact';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Portfolio" element={<Portfolios />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="Project/:title" element={<Project />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
